import * as React from "react";
import {
  Switch,
  Route,
} from "react-router-dom";
import { Contacto } from './Contacto';
import { Empresa } from './Empresa';
import { Gaucho } from './Gaucho';
import { Historia } from './Historia';
import { Productos } from './Productos';

export const AppRouter = () => {
  return (
    <Switch>
      <Route path="/historia">
        <Historia />
      </Route>

      <Route path="/productos/:id">
        <Productos />
      </Route>

      <Route path="/gaucho">
        <Gaucho />
      </Route>

      <Route path="/contacto">
        <Contacto />
      </Route>

      <Route path="/">
        <Empresa />
      </Route>
    </Switch>
);
}