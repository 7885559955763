import { Col, Row } from 'antd';
import * as React from "react";

export const Gaucho = () => {
  return <Row>
    <Col span={12} style={{textAlign: "justify"}}>
      <h2 className="section-header">Gaucho</h2>

      <p>GAUCHO es el resultado de la unión de un grupo de empresas argentinas, líderes en la fabricación de indumentaria de trabajo y tiempo libre, productores de telas, calzado de seguridad y elementos de protección industrial, con una trayectoria de más de 30 años en el mercado local.</p>

      <p>"GAUCHO nace para satisfacer las necesidades del trabajador argentino", con un claro enfoque en la seguridad del trabajador, manejo de la responsabilidad social y cuidado del medio ambiente".</p>

      <p>Las telas para la confección de las prendas GAUCHO cuentan con el aval de Colortex Argentina S.A., con tecnología de punta y equipos de alto rendimiento, siguiendo estándares de calidad internacionales.</p>

      <i><a href="http://www.gauchoargentina.com.ar/">Ingresar a la página de GAUCHO</a></i>
    </Col>

    <Col span={12} style={{padding: "15px", paddingTop: 0}}>
      <img alt="gaucho-01" src="/images/gaucho/gaucho-01.jpg" style={{paddingBottom: "10px"}}/>
      <img alt="gaucho-02" src="/images/gaucho/gaucho-02.jpg" style={{paddingRight: "10px"}} />
      <img alt="gaucho-03" src="/images/gaucho/gaucho-03.jpg" style={{paddingLeft: "10px"}}/>
    </Col>

  </Row>;
}