import * as React from "react";
import { Layout } from "antd";
import {
  BrowserRouter as Router,
} from "react-router-dom";
import { Slideshow } from './Slideshow';
import { AppRouter } from './AppRouter';
import { AppMenu } from './AppMenu';
import { AppHeader } from './AppHeader';
import "./AppLayout.less";

const { Header, Content, Footer } = Layout;

export const AppLayout = () => {

  // Cannot use <Link> outside <Router>
  return <Router>
    <Layout className="layout">
      <Header className="logo">
        <AppHeader />
      </Header>

      <Header>
        <AppMenu />
      </Header>

      <Slideshow />

      <Content style={{ padding: '0 50px' }}>
        <div className="site-layout-content">
          <AppRouter />
          <i style={{ textAlign: 'center', paddingBottom: "50px" }}><small>Copyright © CASA JAIME | 2021</small></i>
        </div>
      </Content>

    </Layout>
  </Router>;
}