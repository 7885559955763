import React from 'react';
import { AppLayout } from './components/AppLayout';
import './App.less';

function App() {
  return (
    <AppLayout />
  );
}

export default App;
