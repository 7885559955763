export const categoriesMap: any = {
    trabajo: "Línea Gaucho Básica de Trabajo",
    campo: "Línea Gaucho de Campo",
    cargo: "Línea Gaucho Cargo",
    fabrica: "Línea Ropa de Fábrica",
    calzado: "Calzados",
    ejecutiva: "Línea de Vestir Ejecutiva",
    impermeable: "Línea Impermeable",
    blanca: "Línea Blanca",
    camperas: "Línea de Camperas, Chalecos y Buzos",
  }
