import * as React from "react";
import { Col, Row } from 'antd';

export const Empresa = () => {
  return <Row>
    <Col span={12} style={{textAlign: "justify"}}>
      <h2 className="section-header">La empresa</h2>

      <p>Hace más de 60 años que vestimos al noroeste argentino con la mejor calidad y servicio.</p>

      <p>Desde 1992 y por más de veinte años hemos fabricado la línea completa de ropa de trabajo <a href="http://www.pamperousointensivo.com.ar/">"Pampero"</a>.</p>

      <p>Hoy hemos volcado todo ese conocimiento y potencial en la confección de la línea de trabajo <a href="http://www.gauchoargentina.com.ar/">"GAUCHO"</a> y su alternativa económica "Pacará"</p>

      <p>Con la marca <a href="http://www.gauchoargentina.com.ar/">"GAUCHO"</a> usted podrá adquirir toda la colección: Pantalones, Camisas, Bombachas de Campo, Vaqueros, Camisas de Jean, Ropa Cargo, Ropa Impermeable, Calzado de Seguridad, Botas, Guantes y Prendas de Abrigo.</p>

      <p>Fabricamos en Tucumán la colección de Camisas y Pantalones de Vestir "JANINE" y Guardapolvos de Arciel.</p>

      <p>Al ser fabricante, logramos el balance ideal entre precio y calidad al estar nuestros productos avalados bajo normas <a href="http://www.iram.org.ar/">IRAM</a>. Además poseemos un considerable stock permanente de indumentarias que le permitirá satisfacer rápidamente su demanda de vestimenta.</p>

      <p><strong>E-Mail:</strong> <a href="mailto:casajaime@casajaime.com.ar">casajaime@casajaime.com.ar</a></p>
    </Col>

    <Col span={12} style={{padding: "20px", paddingTop: 0}}>
      <img alt="empresa-01" src="/images/empresa/empresa-01.jpg" style={{padding: "13px", paddingBottom: "0px"}}/>
      <img alt="empresa-02" src="/images/empresa/empresa-02.jpg" style={{padding: "10px"}} />
      <img alt="empresa-03" src="/images/empresa/empresa-03.jpg" style={{padding: "10px"}}/>
    </Col>

  </Row>;
}