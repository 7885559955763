import * as React from "react";
import { Carousel } from 'antd';

function importAll(r: any) {
  return r.keys().map(r);
}
const images = importAll(require.context("../../public/images/carousel", false, /\.(png|jpe?g|svg)$/));


const contentStyle: React.CSSProperties = {
  height: "330px",
  width: "100%",
  objectFit: "cover"
};

function shuffleArray(array: any) {
  for (var i = array.length - 1; i > 0; i--) {
      var j = Math.floor(Math.random() * (i + 1));
      var temp = array[i];
      array[i] = array[j];
      array[j] = temp;
  }
}

export const Slideshow = () => {
  shuffleArray(images);
  return <Carousel autoplay dots={false}>
    {images.map((e: any) => <div
    key={e.default}>
      <img
      src={e.default}
      style={contentStyle}
    />
    </div>)}

  </Carousel>;
}