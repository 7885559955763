import * as React from "react";
import { Menu } from "antd";
import SubMenu from 'antd/lib/menu/SubMenu';
import {
  Link
} from "react-router-dom";
import { categoriesMap } from '../util/categoriesMap';

export const AppMenu = () => {
  return <Menu className="menu" theme="dark" mode="horizontal">
    <Menu.Item key="historia">
      <Link to="/historia">Historia</Link>
    </Menu.Item>

    <SubMenu
      key="productos"
      title="Productos">
        {Object.keys(categoriesMap).map(e => {
          return <Menu.Item key={e}>
            <Link to={`/productos/${e}`}>{categoriesMap[e]}</Link>
          </Menu.Item>;
        })}
    </SubMenu>

    <Menu.Item key="gaucho">
      <Link to="/gaucho">Gaucho</Link>
    </Menu.Item>

    <Menu.Item key="contacto">
      <Link to="/contacto">Contacto</Link>
    </Menu.Item>
  </Menu>;
}