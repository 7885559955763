import * as React from "react";
import { Col, Row } from 'antd';
import { Link } from 'react-router-dom';

export const AppHeader = () => {

  return <Row
    align="middle"
    justify="space-between"
   style={{ height:"100%" }}>
    <Col>
      <Link to="/"><img alt="logo" src="/images/logo.png" /></Link>
    </Col>

    <Col>
      <a href="https://www.facebook.com/CasaJaime">
        <img className="social" alt="facebook" src="/images/facebook.png" />
      </a>

      <a href="https://www.instagram.com/casajaimetucuman">
        <img className="social" alt="instagram" src="/images/instagram.jpg" />
      </a>
    </Col>
  </Row>;
}