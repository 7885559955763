import { Card, Col, Divider, Row, Tag } from 'antd';
import * as React from "react";

interface SingleProductProps {
  title: string;
  image: string;
  brand: string[];
  size: string;
  description: string;
  colors: any;
}

export const SingleProduct = (props: SingleProductProps) => {
  const {
    title,
    image,
    brand,
    size,
    description,
    colors,
  } = props;

  const t = <span style={{whiteSpace: "initial"}}>
    {title}
  </span>;

  return <Card title={t} style={{ width: "45%", margin: "10px", border: "1px solid black" }}>
  <img src={`/images/productos/${image}`} style={{ width: "100%" }} />

  <Divider style={{margin: "12px 0"}}/>

  <Row align="middle" style={{backgroundColor: "rgb(180, 180, 180)", textAlign: "center", color: "white", fontWeight: "bold"}}>
    <Col span={2} style={{padding: "5px"}}>
      <span style={{display: "block"}}>MARCA</span>
      {brand.map(b => <img src={`/images/productos/brands/${b}.jpg`} style={{ width: "50px" }} />)}
    </Col>

    <Col span={22}>
      <span>{`TALLES ${size}`}</span>
    </Col>
  </Row>

  <div style={{backgroundColor: "rgb(180, 180, 180)", textAlign: "center", color: "white", fontWeight: "bold", marginTop: "10px"}}>
    <small><p>COLORES | CÓDIGO (especial / común)</p></small>
  </div>

  <Row justify="space-around">
    {Object.keys(colors).map(k => {
      return <Color key={k} name={k} code={colors[k]}/>
    })}
  </Row>

  <Divider style={{margin: "12px 0"}}/>

  <div style={{padding: "15px", textAlign: "justify"}} dangerouslySetInnerHTML={{__html: description}} />
</Card>;
}

const colorsMap: any = {
  blanco: "#ffffff",
  negro: "#000000",
  beige: "#B48952",
  azulina: "#859ECF",
  azul: "#108ee9",
  oliva: "#6C6348",
  marino: "#021F33",
  francia: "#0E0EC2",
  naranja: "#FF5525",
  tiza: "#AFC3C4",
  bataraza: "#233F55",
  marron: "#643101",
  gris: "#808080",
  amarillo: "#FCEE21",
  rojo: "#C1272D",
  cemento: "#697D74"
}

const Color = (props: {name: string, code: string}) => {
  const {name, code} = props;
  return <div style={{width: "80px", textAlign: "center"}}>
    <small>
      {name != "blanco" && <Tag color={colorsMap[name]} style={{display: "block", textTransform: "uppercase"}}>{name}</Tag>}
      {name == "blanco" && <Tag style={{display: "block", backgroundColor: "#ffffff"}}>BLANCO</Tag>}

      {code}
    </small>
  </div>;
}