import { Col, Row } from 'antd';
import * as React from "react";

export const Contacto = () => {

  return <div>

    <Row gutter={8} justify="space-between">
      <Col span={11}>

        <span>
          <p><strong>Casa Central</strong></p>

          <p>Av. Brigido Terán 75 | Tucumán | Argentina</p>

          <p>Tel: +54 (381) 421-4338 / 421-1339 | Fax: +54 (381) 431-0540</p>
          <p><strong>Lunes a Sábado | 08:30 a 13:00 y 16:30 a 20:00</strong></p>
        </span>

        <iframe
        width="490" height="275" scrolling="no" style={{border: 0}}
        src="https://maps.google.com/maps?f=q&source=s_q&hl=en&q=Av.%20Brigido%20Ter%C3%A1n%2075%2C%20Tucum%C3%A1n%2C%20Argentina&aq=0&ie=UTF8&t=m&z=13&iwloc=A&output=embed" />

        <p><strong>E-Mail:</strong> <a href="mailto:casajaime@casajaime.com.ar">casajaime@casajaime.com.ar</a></p>
      </Col>

      <Col span={11}>
        <span>
          <p><strong>Sucursal</strong></p>

          <p>24 de Septiembre 44 | Tucumán | Argentina</p>

          <p>Tel: +54 (381) 430-6615</p>
          <p><strong>Lunes a Sábado | 08:30 a 13:00 y 16:30 a 20:00</strong></p>
        </span>

        <iframe
        width="490" height="275" scrolling="no" style={{border: 0}}
        src="https://maps.google.com/maps?f=q&source=s_q&hl=en&q=24%20de%20Septiembre%2044%2C%20Tucum%C3%A1n%2C%20Argentina&aq=0&ie=UTF8&t=m&z=14&iwloc=A&output=embed" />
      </Col>
    </Row>

    <Row justify="center">
      <a href="/assets/casa-jaime-srl-convenios.zip">
        <img alt="Descargue nuestras Inscripciones Fiscales" src="/images/contacto/fiscales.png"/>
      </a>
    </Row>
  </div>;
}