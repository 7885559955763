import * as React from "react";
import { Col, Divider, Row } from 'antd';

export const Historia = () => {

  return <div style={{textAlign: "justify"}}>
      <h2 className="section-header" style={{textAlign: "center"}}>CASA JAIME, UN EJEMPLO DE CRECIMIENTO AL RITMO DE UNA NACIÓN</h2>

      <Row style={{lineHeight: 1.3}}>
        <Col span={11}>
          <p>Don Jananie Chervonagura fue uno de los tantos inmigrantes europeos que llegó a nuestro país en la década del veinte del XX, dejando una Europa de entre guerra sumida en el hambre por una esperanzadora Argentina conocida como el granero del mundo.</p>

          <p>Se estableció en la tucumana ciudad de Monteros, centro de la pujante industria azucarera del sur tucumano. Conoció a Reizel, con quien formaría la primera generación de argentinos. Se dedicó al rubro textil, comenzando con la compra venta ambulante, para luego de varias décadas abrir una humilde tienda a la que llamó “Casa Jaime”.</p>

          <p>Para que sus hijos concurran a la universidad local, en 1953 se traslada a San Miguel de Tucumán, a la estratégica zona de “El Bajo” por tener las Estaciones de Ómnibus y de Ferrocarril, siendo así un lugar clave en la logística en transporte del NOA y por ende, de intensa labor comercial.</p>

          <p>En la década del 60, Don Jananie, apostando por el país que generosamente lo recibió, decide reinvertir, comenzando con la fabricación de prendas de vestir, lográndose así el autoabastecimiento con un sostenido crecimiento, a pesar de los vaivenes político-económicos argentinos.</p>

          <p>A principio de los 90, Casa Jaime fue elegida por Alpargatas SAIC para ser la primera en confeccionar y comercializar la flamante línea de ropa de trabajo Pampero como licenciatario exclusivo para el NOA, generándose un impacto importante a nivel nacional que le daría reconocimiento y prestigio por la calidad y la prolijidad de la confección.</p>

          <p>Como la demanda de ropa de trabajo tenía un sostenido crecimiento por las recientes leyes laborales y Tucumán inauguraba una nueva Terminal de Ómnibus, Casa Jaime inauguró una nueva y cómoda casa central frente a dicho nuevo centro estratégico, adaptándose así nuevamente a los cambios exigidos por el mercado, quedando el local del “El bajo” como una sucursal para la venta minorista.</p>
        </Col>

        <Col span={2}>
          <Row align="middle" justify="center" style={{height: "100%"}}>
            <Divider type="vertical" style={{height: "100%"}}/>
          </Row>
        </Col>

        <Col span={11}>
          <p>Con la venta de la marca Pampero en 2012, Casa Jaime perdió la franquicia, pero esa vicisitud fue encarada con la tenacidad que lo caracteriza, uniéndose con otras firmas argentinas, líderes en la fabricación de indumentaria de trabajo y tiempo libre, ampliando su campo de venta al calzado de seguridad y elementos de protección industrial, naciendo así la marca “GAUCHO” y su versión económica “Pacará”</p>

          <p>Casa Jaime actualmente cuenta con distribuidores y viajantes que abastecen a todo el NOA, dando prueba de la aceptación que se tiene en el mercado consumidor y también el desarrolla de una intensa actividad pública a través de la presencia constante en licitaciones públicas de diferentes reparticiones oficiales.</p>

          <p>La marca “GAUCHO”, por su parte es muy requerida por la industria y el campo ya que puede proporcionar ropa de trabajo, bombachas, mamelucos, ropa de denim y una variada colección de prendas requeridas para el tiempo libre.</p>

          <p>Por otra parte, al ser fabricantes, se cuenta con un stocks permanente de sus diferentes productos avalados por las exigentes normas IRAM lo cual le permite concretar entrega inmediatas de todos sus rubros en la provincia de Tucumán y en el NOA en general.</p>

          <p>De esta manera, lo que fue en sus comienzos una pequeña empresa familiar pudo seguir el derrotero soñado por su fundador y amoldarse a los diferentes vaivenes nacionales, respetando fundamentalmente la ética y los principios basados en el trabajo y el esfuerzo, que le dieron el prestigio y respeto del mercado.</p>
        </Col>
      </Row>
    </div>;
}