import * as React from "react";
import { Row } from 'antd';
import { useParams } from "react-router-dom";
import { categoriesMap } from '../util/categoriesMap';
import { SingleProduct } from './SingleProduct';
import productos from "../../public/assets/productos.json"

export const Productos = () => {
  const {id} = useParams() as any;

  const prod = productos as any

  return <div>
      <h2 className="section-header">{categoriesMap[id]}</h2>
    <Row>
      {(prod[id] || []).map((p: any) => {
        return <SingleProduct
        key={p.nombre}
        title={p.nombre}
        image={p.imagen}
        brand={p.marcas}
        size={p.talles}
        description={p.descripcion}
        colors={p.colores}
      />
      })}

    </Row>
  </div>;
}